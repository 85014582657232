import {
  fetchingDataWithAxiosMiddleware,
  set_response_notification,
  setFilterParams,
  setServerIsDown,
} from "./commonAction";
import apiService from "../../serviceUtils";
import { setRugs } from "./rugs";
import { setFloorDecor, setTableDecor, setWallDecor } from "./decor";
import { commonFilter } from "../../helper/queryParamsAndResponseData";

export const SET_AUTH_INFO = "SET_AUTH_INFO";

export const LOG_OUT = "LOG_OUT";

export const log_out = () => {
  setFilterParams({
    walls: commonFilter("walls", true),
    floors: commonFilter("floors", true),
    forms: commonFilter("forms", true),
    rugs: commonFilter("rugs", true),
    wallDecors: commonFilter("wallDecors", true),
    floorDecors: commonFilter("floorDecors", true),
    tableDecors: commonFilter("tableDecors", true),
  });
  return {
    type: LOG_OUT,
  };
};

export const set_auth_info = (payload) => {
  localStorage.setItem("auth_info", JSON.stringify(payload));
  return {
    type: SET_AUTH_INFO,
  };
};

export const login = (data) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("password", data.password);
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${apiService.login()}`,
      formData
    );

    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(set_auth_info({ ...data }));
        dispatch(setRugs([]));
        dispatch(setWallDecor([]));
        dispatch(setFloorDecor([]));
        dispatch(setTableDecor([]));
        dispatch(
          set_response_notification({ type: "success", text: "Success" })
        );
      }
    }
  } catch (error) {
    if (error.message === "Network Error") {
      dispatch(setServerIsDown(true));
    }
    const message = error?.response
      ? error?.response?.data?.message
      : error.message;
    dispatch(
      set_response_notification({
        type: "error",
        text: "Warning ->  " + message,
      })
    );
  }
};
