import React, { useEffect, useState } from "react";
import { Button, Modal, Spin, Table } from "antd";
import "./modal.css";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { get_filter_floors } from "../../Redux/Actions/floor";
import { get_filter_walls } from "../../Redux/Actions/wall";
import {
  floors_in_preprocess,
  preprocess_floors,
} from "../../Redux/Actions/floor";
import {
  preprocess_walls,
  walls_in_preprocess,
} from "../../Redux/Actions/wall";
import { useLocation } from "react-router-dom";

const ProcessModal = ({ open, setOpen }) => {
  const location = useLocation();
  const state = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { floors, walls, vendors, in_progress_walls, in_progress_floors } =
    state;

  const [disable, setDisable] = useState(false);

  const allItems = location.pathname === "/floors" ? floors : walls;

  const filteredItems = allItems.filter((item) => item.preprocessed === false);

  const filterItemsColumnForWalls = [
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorID",
      render: (record) => {
        const vendor = vendors.find((vendor) => +vendor.key === record);
        return vendor ? vendor.name : "No Vendor";
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      render: (record) => (
        <div
          className={"colorDiv"}
          style={{ background: record }}
          title={record}
        />
      ),
    },
    {
      title: "Logo",
      dataIndex: "logo",
      render: (record) => <img src={record} alt="wall logo" width={30} />,
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (record) => {
        return (
          <div className="actions">
            {record.status === "in preprocess" ? (
              <Spin />
            ) : record.status === "not preprocessed" ? (
              <CloseCircleOutlined style={{ fontSize: "150%" }} />
            ) : record.status === "preprocessed" ? (
              <CheckCircleOutlined style={{ fontSize: "150%" }} />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const filterItemsColumnForFloors = [
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorID",
      render: (record) => {
        const vendor = vendors.find((vendor) => vendor.key === record);
        return vendor ? vendor.name : "No Vendor";
      },
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Material",
      dataIndex: "material",
      render: (record) => <img src={record} alt="material logo" width={30} />,
    },
    {
      title: "Logo",
      dataIndex: "logo",
      render: (record) => <img src={record} alt="floor logo" width={30} />,
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (record) => (
        <div className="actions">
          {record.status === "in preprocess" ? (
            <Spin />
          ) : record.status === "not preprocessed" ? (
            <CloseCircleOutlined style={{ fontSize: "150%" }} />
          ) : record.status === "preprocessed" ? (
            <CheckCircleOutlined style={{ fontSize: "150%" }} />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  const columns =
    location.pathname === "/floors"
      ? filterItemsColumnForFloors
      : filterItemsColumnForWalls;
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    if (
      (location.pathname === "/floors" && !in_progress_floors) ||
      (location.pathname === "/walls" && !in_progress_walls)
    )
      if (!disable) {
        dispatch(get_filter_floors());
        dispatch(get_filter_walls());
        setOpen();
      }
  };
  const startProcessing = () => {
    if (location.pathname === "/floors") {
      dispatch(preprocess_floors());
      setTimeout(dispatch(floors_in_preprocess()), 1000);
    } else {
      dispatch(preprocess_walls());
      setTimeout(dispatch(walls_in_preprocess()), 1000);
    }
  };
  useEffect(() => {
    if (in_progress_walls?.length || in_progress_floors?.length) {
      setDisable(true);
      setTimeout(() => {
        if (location.pathname === "/floors") {
          dispatch(floors_in_preprocess());
        } else {
          dispatch(walls_in_preprocess());
        }
      }, 7000);
    } else {
      if (disable) {
        setOpen();
      }
    }
  }, [in_progress_walls, in_progress_floors]);
  return (
    <Modal
      title={"Processing"}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={""}
    >
      <Table
        className={"globalTable"}
        dataSource={
          in_progress_floors
            ? in_progress_floors
            : in_progress_walls
            ? in_progress_walls
            : filteredItems
        }
        columns={columns}
        size={"small"}
        bordered
      />
      <Button onClick={startProcessing} type={"primary"} disabled={disable}>
        Start Processing
      </Button>
    </Modal>
  );
};

export default ProcessModal;
