import React, { useRef, useState } from "react";
import { Form, Input, InputNumber, Switch, Select } from "antd";

import { useLocation } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons";
import { TileInput } from "../../../../helper/miniComponents";
import { vendorAndTypeOptions } from "../../../../helper/vendor";
import { activeTab } from "../../../../helper/routeAndNavigation";
import { sewingPatternOptions } from "../../../../helper/utils";
import { validateURL } from "../../../../helper/validate";

const AddFloor = ({
  setName,
  auth_info,
  vendors,
  floorGlobalType,
  materialFiles,
  onChangeLength,
  handler_sphere,
  materials,
  setMaterialFiles,
  setMaterials,
  selectOnChange,
  name,
  forAdd,
  material,
  setMaterial,
  setMaterialFile,
}) => {
  const location = useLocation();
  const [isCarpet, setIsCarpet] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const fileInputRef = useRef();
  const typeOptions = vendorAndTypeOptions(
    vendors,
    activeTab(location.pathname),
    true
  ).filter((element) => selectedVendor?.includes(element.value));

  return (
    <div>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Please input name!" },
          { max: 100, message: "Too Long" },
        ]}
        // getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input onChange={(e) => setName(e.target.value)} />
      </Form.Item>
      {name.length > 1 ? (
        <Form.Item
          label="Code"
          name="code"
          initialValue={`${name.slice(0, 2).toUpperCase()}${Math.floor(
            Math.random() * 99999
          )}`}
          rules={[{ required: true, message: "Please input code!" }]}
          style={{ display: auth_info.isVendor && "none" }}
          getValueFromEvent={(e) => e.target.value.trim()}
        >
          <Input disabled={name.length < 2} />
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item
        label="URL"
        name="url"
        rules={[
          { required: true, message: "Please input url!" },
          { validator: validateURL },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input />
      </Form.Item>
      {!auth_info.isVendor && forAdd ? (
        <Form.Item
          label="Vendor"
          name="vendorID"
          rules={[{ required: true, message: "Please select vendor!" }]}
          getValueFromEvent={(e) => {
            let selectedVendorTypes;
            vendorAndTypeOptions(
              vendors,
              activeTab(location.pathname),
              false
            ).forEach((item) => {
              if (item.value === e) {
                selectedVendorTypes = item.types;
              }
            });

            setSelectedVendor(selectedVendorTypes);
            return e;
          }}
        >
          <Select
            options={vendorAndTypeOptions(
              vendors,
              activeTab(location.pathname),
              false
            )}
          />
        </Form.Item>
      ) : (
        ""
      )}
      {forAdd ? (
        <>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please select type!" }]}
          >
            <Select
              options={typeOptions}
              onChange={(e) => {
                if (e === "FLOOR_CARPET") {
                  setIsCarpet(true);
                } else {
                  setIsCarpet(false);
                }
              }}
            />
          </Form.Item>
          {isCarpet ? (
            <Form.Item label="Sewing pattern" name="sewingPattern">
              <Input placeholder={"Smoother"} disabled />
            </Form.Item>
          ) : (
            <Form.Item
              label="Sewing pattern"
              name="sewingPattern"
              rules={[
                {
                  required: true,
                  message: "Please select Sewing pattern!",
                },
              ]}
            >
              <Select
                options={sewingPatternOptions}
                onChange={(e) => selectOnChange(e, "pattern")}
              />
            </Form.Item>
          )}

          {floorGlobalType && floorGlobalType !== "carpet" ? (
            <>
              <i style={{ marginLeft: "100px" }}>Select Tiles</i>
              <div
                className={"tilesContent"}
                style={{
                  display:
                    floorGlobalType && floorGlobalType !== "carpet"
                      ? "flex"
                      : "block",
                  flexWrap:
                    floorGlobalType && floorGlobalType !== "carpet"
                      ? "wrap"
                      : "nowrap",
                }}
              >
                {materialFiles.length ? (
                  <>
                    {materialFiles.map((item, index) => {
                      if (item) {
                        return (
                          <div key={index} className={"uploadTileAndLength"}>
                            <InputNumber
                              placeholder={"width(inch)"}
                              onChange={(event) => onChangeLength(event, index)}
                            />
                            <TileInput
                              count={index}
                              handler_sphere={handler_sphere}
                              materialFiles={materialFiles}
                              materials={materials}
                              floorGlobalType={floorGlobalType}
                              setMaterialFiles={setMaterialFiles}
                              setMaterials={setMaterials}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                    {materialFiles[materialFiles.length - 1] === null ? (
                      <div
                        key={materialFiles.length - 1}
                        className={"uploadTileAndLength"}
                      >
                        <TileInput
                          count={materialFiles.length - 1}
                          handler_sphere={handler_sphere}
                          materialFiles={materialFiles}
                          materials={materials}
                          floorGlobalType={floorGlobalType}
                          setMaterialFiles={setMaterialFiles}
                          setMaterials={setMaterials}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <div className={"uploadTileAndLength"} style={{ width: "300px" }}>
              {materialFiles[0] && (
                <InputNumber
                  placeholder={"width(inch)"}
                  onChange={(event) => onChangeLength(event, 0)}
                />
              )}
              <TileInput
                count={0}
                handler_sphere={handler_sphere}
                materialFiles={materialFiles}
                materials={materials}
                floorGlobalType={floorGlobalType}
                setMaterialFiles={setMaterialFiles}
                setMaterials={setMaterials}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <Form.Item label="Editable" name="editable" valuePropName="checked">
            <Switch checkedChildren="editable" unCheckedChildren="editable" />
          </Form.Item>
          <div className={"selectFile"} style={{ marginLeft: "13%" }}>
            <i style={{ margin: "20px" }}>
              {" "}
              {!material && <b style={{ color: "red" }}> * </b>}
              {material ? "Change" : "Select"} Material
            </i>
            <label
              htmlFor="button-file3"
              style={{
                border: "1px solid black",
                width: "80px",
                height: "80px",
                cursor: "pointer",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <input
                accept=".png, .jpg, .jpeg, .svg"
                id="button-file3"
                style={{ display: "none" }}
                ref={fileInputRef}
                type="file"
                onChange={(e) => handler_sphere(e, "material")}
              />
              {material ? (
                <div>
                  <img
                    src={material}
                    alt="uploaded logo"
                    className={"reloadImage"}
                  />
                </div>
              ) : (
                <CloudUploadOutlined style={{ fontSize: "200%" }} />
              )}
            </label>
            {material && (
              <span
                className={"imageX"}
                style={{ left: "228px" }}
                onClick={() => {
                  setMaterial(null);
                  fileInputRef.current.value = "";
                  setMaterialFile(null);
                }}
              >
                x
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default AddFloor;
