import React, { useRef } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { validateNumber, validateURL } from "../../../../helper/validate";
import { vendorTypes } from "../../../../helper/vendor";

const AddVendor = ({
  costPerView,
  logo,
  setCostPerView,
  costPerViewList,
  setLogo,
  setLogoFile,
  setName,
  handler_sphere,
  forAdd,
}) => {
  const fileInputRef = useRef();
  return (
    <div>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Please input name!" },
          { max: 100, message: "Too Long" },
        ]}
        // getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input onChange={(e) => setName(e.target.value)} />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email!",
          },
          {
            type: "email",
            message: "The input is not a valid email!",
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        label="Type"
        name="types"
        rules={[{ required: true, message: "Please select type!" }]}
      >
        <Select mode={"multiple"} options={vendorTypes} />
      </Form.Item>
      <Form.Item
        label="URL"
        name="url"
        rules={[
          { required: true, message: "Please input url!" },
          { validator: validateURL },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Monthly Budget"
        name="monthlyBudget"
        rules={[
          { required: true, message: "Please input Monthly Budget!" },
          // { max: 100, message: "Too Long" },
          { validator: validateNumber },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input />
      </Form.Item>
      <div className={"selectFile"} style={{ marginLeft: "8%" }}>
        <i style={{ margin: "20px" }}>
          {" "}
          {!logo && <b style={{ color: "red" }}> * </b>}{" "}
          {logo ? "Change" : "Select"} Logo
        </i>
        <label htmlFor="button-file-logo">
          <input
            accept=".png, .jpg, .jpeg, .svg"
            id="button-file-logo"
            style={{ display: "none" }}
            ref={fileInputRef}
            type="file"
            onChange={(e) => handler_sphere(e, "logo")}
          />
          {logo ? (
            <div>
              <img src={logo} alt="uploaded logo" className={"reloadImage"} />
            </div>
          ) : (
            <CloudUploadOutlined style={{ fontSize: "200%" }} />
          )}
        </label>
        {logo && (
          <span
            className={"imageX"}
            style={{ left: "210px" }}
            onClick={() => {
              setLogo(null);
              fileInputRef.current.value = "";
              setLogoFile(null);
            }}
          >
            x
          </span>
        )}
      </div>
      <div className={"impressionLimit"}>
        Impression Limit {costPerViewList(costPerView).length}
        <br />
        {forAdd
          ? costPerView.map((item, index) => {
              return (
                <InputNumber
                  placeholder={`${index + 1} impression`}
                  key={index}
                  onChange={(value) => {
                    costPerView[index] = `${
                      typeof value === "number" ? `${value}` : ""
                    }`;
                    setCostPerView([...costPerView]);
                    if (
                      costPerView[index] &&
                      costPerView[costPerView.length - 1] &&
                      costPerView.length < 10
                    ) {
                      costPerView.push(0);
                      setCostPerView([...costPerView]);
                    }
                  }}
                />
              );
            })
          : costPerView.map((item, index) => {
              return (
                <InputNumber
                  placeholder={`${index + 1} impression`}
                  key={index}
                  value={item}
                  onChange={(value) => {
                    costPerView[index] = `${
                      typeof value === "number" ? `${value}` : ""
                    }`;
                    setCostPerView([...costPerView]);
                    if (
                      costPerView[index] &&
                      costPerView[costPerView.length - 1] &&
                      costPerView.length < 10
                    ) {
                      costPerView.push(0);
                      setCostPerView([...costPerView]);
                    }
                  }}
                />
              );
            })}
      </div>
    </div>
  );
};
export default AddVendor;
