import React, { useEffect, useRef, useState } from "react";
import { Button, DatePicker, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  get_requests,
  get_space_info,
} from "../../Redux/Actions/transferDataAction";
import { get_filter_floors } from "../../Redux/Actions/floor";
import { get_filter_walls } from "../../Redux/Actions/wall";
import {
  set_is_modal,
  set_min_width_header,
  set_tablesPageInfo,
} from "../../Redux/Actions/commonAction";
import ViewErrorModal from "../modals/viewErrorButton";
import StillPhoto from "./stillPhoto/stillPhoto";
import LogsChart from "./LogsChart/logsChart";
import BackendErrors from "./backendErrors/backendErrors";
import DebuggingInfo from "./debuggingInfo/debuggingInfo";
import Scraping from "./scraping/scraping";

const Logs = () => {
  const state = useSelector((state) => state.common);
  const {
    loading,
    requests,
    active_tab_in_logs,
    tablesPageInfo,
    walls,
    floors,
    space_info,
  } = state;
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const filterParamsLogs = useRef({ startDate: "", endDate: "", status: "" });
  const [showError, setShowError] = useState(false);
  const { RangePicker } = DatePicker;
  const tablePageOnChange = (e) => {
    dispatch(set_tablesPageInfo({ ...tablesPageInfo, logs: e }));
  };
  const allProcessColumn = [
    {
      title: "ID",
      dataIndex: "key",
      sorter: (a, b) => b.key - a.key,
    },

    {
      title: (
        <span>
          Original Photo Name (OPN), <br />
          <hr />
          Result Image Name (RIN)
        </span>
      ),
      dataIndex: "",
      render: (record) => {
        return (
          <>
            <p>
              OPN -{" "}
              {record.originalPhotoName ? record.originalPhotoName : "Empty"}
            </p>
            <hr />
            <p>RIN - {record.photo_name ? record.photo_name : "Empty"}</p>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Wall Color ID (WCID), <br />
          <hr />
          Floor Tile ID (FTID)
        </span>
      ),
      dataIndex: "",
      render: (record) => {
        return (
          <>
            <p>WCID - {record.wallColorID ? record.wallColorID : "Empty"}</p>
            <hr />
            <p>FTID - {record.floorTileID ? record.floorTileID : "Empty"}</p>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Type (T)
          <br />
          <hr />
          Status (S)
        </span>
      ),
      dataIndex: "",
      sorter: (a, b) => b.status.localeCompare(a.status),
      render: (record) => (
        <>
          <p>T - {record.type}</p>
          <hr />
          {record.status === "ERROR" ? (
            <Button
              className={"viewErrorButton"}
              onClick={() => {
                setShowError(record.errorStackTrace);
              }}
            >
              View Error
            </Button>
          ) : (
            <p>
              <b>S - {record.status}</b>
            </p>
          )}
        </>
      ),
    },
    {
      title: "Time (Second)",
      dataIndex: "time",
    },
    {
      title: (
        <span>
          Session ID (SID) <br />
          <hr /> IP Address(IA)
        </span>
      ),
      dataIndex: "",
      render: (record) => (
        <>
          <p>
            SID -{" "}
            {record.requestAttributes?.sessionID
              ? record.requestAttributes?.sessionID
              : "Empty"}
          </p>
          <hr />
          <p>
            IA -{" "}
            {record.requestAttributes?.ipAddress
              ? record.requestAttributes?.ipAddress
              : "Empty"}
          </p>
        </>
      ),
    },
    {
      title: (
        <span>
          Task Added To Queue (TAQ),
          <br />
          <hr />
          Started Processing (SP), <br />
          <hr />
          Status Completed (SC)
        </span>
      ),
      dataIndex: "",
      render: (record) => (
        <>
          <p>TAQ - {record.taskAddedToQueue}</p>
          <hr />
          <p>SP - {record.startedProcessing}</p>
          <hr />
          <p>SC - {record.statusCompleted}</p>
        </>
      ),
    },
    {
      title: "Algo Version",
      dataIndex: "versions",
      render: (record) => (
        <Button
          onClick={() => {
            dispatch(set_is_modal({ open: record, type: "viewAlgoVersions" }));
          }}
        >
          View
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (
      !requests.length &&
      (active_tab_in_logs === "charts" ||
        active_tab_in_logs === "table" ||
        active_tab_in_logs === "stillphoto")
    ) {
      dispatch(get_requests(filterParamsLogs.current));
    }
    if (!walls.length) {
      dispatch(get_filter_walls());
    }
    if (!floors.length) {
      dispatch(get_filter_floors());
    }
  }, [dispatch, active_tab_in_logs]);
  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch, active_tab_in_logs]);

  useEffect(() => {
    if (active_tab_in_logs === "charts") {
      setTimeout(() => dispatch(get_space_info()), 10000);
    }
  }, [dispatch, space_info, active_tab_in_logs]);
  useEffect(() => {
    if (!space_info) {
      dispatch(get_space_info());
    }
  }, []);
  return (
    <div>
      {active_tab_in_logs === "table" ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <RangePicker
              format={"YYYY/MM/DD"}
              onChange={(e) => {
                filterParamsLogs.current = {
                  ...filterParamsLogs.current,
                  startDate: Date.parse(
                    JSON.parse(JSON.stringify(e ? e[0] : ""))
                  ),
                  endDate: Date.parse(
                    JSON.parse(JSON.stringify(e ? e[1] : ""))
                  ),
                };
                // filterParamsLogs.current = {
                //     ...filterParamsLogs.current,
                //     startDate: moment(JSON.parse(JSON.stringify(e[0]))).format(
                //         "YYYY-MM-DD"
                //     ),
                //     endDate: moment(JSON.parse(JSON.stringify(e[1]))).format(
                //         "YYYY-MM-DD"
                //     ),
                // };
                dispatch(get_requests(filterParamsLogs.current));
              }}
            />
            <Select
              placeholder={<span>Filter by types</span>}
              style={{ width: "150px" }}
              // mode={"multiple"}
              defaultValue={"ALL"}
              options={[
                { label: "ALL", value: "" },
                { label: "COMPLETED", value: "COMPLETED" },
                { label: "ERROR", value: "ERROR" },
                { label: "PENDING", value: "PENDING" },
              ]}
              onChange={(e) => {
                filterParamsLogs.current = {
                  ...filterParamsLogs.current,
                  status: e,
                };
                dispatch(get_requests(filterParamsLogs.current));
                // filterParamsLogs["vendors"] = e;
                // dispatch(setFilterParams({ ...filterParamsLogs }));
              }}
            />
          </div>
          <Table
            ref={tableRef}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            size={"small"}
            className={"globalTable"}
            loading={loading.requests}
            dataSource={requests}
            columns={allProcessColumn}
            bordered
            onChange={tablePageOnChange}
            pagination={tablesPageInfo.logs}
          />
        </>
      ) : active_tab_in_logs === "charts" ? (
        <LogsChart />
      ) : active_tab_in_logs === "backendErrors" ? (
        <BackendErrors />
      ) : active_tab_in_logs === "debuggingInfo" ? (
        <DebuggingInfo />
      ) : active_tab_in_logs === "scraping" ? (
        <Scraping />
      ) : (
        <StillPhoto />
      )}
      {showError ? (
        <ViewErrorModal open={showError} setOpen={() => setShowError(false)} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Logs;
