import React from "react";
import { Button, Input, Modal } from "antd";
import "./modal.css";
import { useDispatch } from "react-redux";
import { decideApiForScraping } from "../../helper/utils";
import { set_is_modal } from "../../Redux/Actions/commonAction";

const ScrapeProblemModal = ({ open, setOpen }) => {
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  const [inputValue, setInputValue] = React.useState("");
  const dispatch = useDispatch();
  const handleSave = () => {
    dispatch(set_is_modal({ open: false, type: "scrapeProblem" }));
  };
  const SaveScrapedOrDbValue = (open, objectKey) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Button
          style={{ marginRight: "5px" }}
          onClick={() => {
            dispatch(
              decideApiForScraping(open.productType, {
                [objectKey]: open.dbValue,
                key: open.productId,
              })
            );
            handleSave();
          }}
        >
          Save Db Value
        </Button>
        <Button
          onClick={() => {
            dispatch(
              decideApiForScraping(open.productType, {
                [objectKey]: open.scrappedValue,
                key: open.productId,
              })
            );
            handleSave();
          }}
        >
          Save Scraped Value
        </Button>
      </div>
    );
  };
  return (
    <div>
      <Modal
        title={""}
        open={open}
        width={700}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        {" "}
        {open.type === "inStock" ? (
          <>
            <p style={{ textAlign: "center" }}>
              db Value - {`${open.dbValue ? "In Stock" : "Out of Stock"}`}
            </p>
            <p style={{ textAlign: "center" }}>
              scraped Value -{" "}
              {`${open.scrappedValue ? "In Stock" : "Out of Stock"}`}
            </p>
          </>
        ) : (
          ""
        )}
        {open.type === "sku" ||
        open.type === "name" ||
        open.type === "width" ||
        open.type === "height" ? (
          <>
            <div
              style={{
                display: "flex",
                // flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              <Input
                style={{ width: "50%", marginRight: "5px" }}
                placeholder={"Valid " + open.type}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <Button
                onClick={() => {
                  dispatch(
                    decideApiForScraping(open.productType, {
                      key: open.productId,
                      [open.type]: inputValue,
                    })
                  );
                  handleSave();
                }}
              >
                Save
              </Button>
            </div>
            {SaveScrapedOrDbValue(open, open.type)}
          </>
        ) : (
          ""
        )}
        {open.type === "price" ? (
          <>
            <div
              style={{
                display: "flex",
                // flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              <Input
                style={{ width: "50%", marginRight: "5px" }}
                placeholder={"Valid Price"}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <Button
                onClick={() => {
                  dispatch(
                    decideApiForScraping(open.productType, {
                      key: open.productId,
                      url: inputValue,
                    })
                  );
                  handleSave();
                }}
              >
                Save
              </Button>
            </div>
            {SaveScrapedOrDbValue(open, "price")}
          </>
        ) : (
          ""
        )}
        {open.type === "url" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ textAlign: "center", color: "red" }}>
              URL - {open.productURL}
            </p>
            <Input
              style={{ width: "50%", marginTop: "5px" }}
              placeholder={"Valid Url"}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <br />
            <Button
              onClick={() => {
                dispatch(
                  decideApiForScraping(open.productType, {
                    key: open.productId,
                    url: inputValue,
                  })
                );
                handleSave();
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          ""
        )}
        {open.type === "image" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p style={{ textAlign: "center" }}>
              Similarity - {open.similarity}
            </p>
            <img style={{ width: "50%" }} src={open.dbValue} alt="dbValue" />
            <br />
            <img
              style={{ width: "50%" }}
              src={open.scrappedValue}
              alt="scrapedValue"
            />
          </div>
        ) : (
          ""
        )}
        {open.type === "inStock" ? SaveScrapedOrDbValue(open, "inStock") : ""}
      </Modal>
    </div>
  );
};

export default ScrapeProblemModal;
