import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spin, Table, Tooltip } from "antd";
import "../LogsChart/logsCharts.css";
import { getScraping } from "../../../Redux/Actions/transferDataAction";
import { set_is_modal } from "../../../Redux/Actions/commonAction";
import { deepEqual } from "../../../helper/utils";
import { DeleteOutlined } from "@ant-design/icons";

const Scraping = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const { debuggingInfo, loading } = state;
  // const tableRef = useRef(null);
  let disable = false;
  const scrapeData = {
    startDate: "2024-10-17T11:07:49.142026059Z",
    endDate: "2024-10-17T11:07:50.021362635Z",
    status: "DONE",
    resultPath: "https://assets.stg.dospace.tech/test_results/automation",
    results: [
      {
        id: 0,
        key: 0,
        productId: 70,
        productType: "FLOORTILE",
        productName: {
          scrapped: true,
          dbValue: "Makoto Umi Terracotta",
          scrappedValue: "Makoto Umi Terracotta",
        },
        vendor: "Bedrosians",
        startDate: "2024-10-17T11:07:49.142669740Z",
        endDate: "2024-10-17T11:07:49.142678110Z",
        status: "IN_PROGRESS",
        duplicatedByName: true,
        duplicatedByImage: false,
        productURL:
          "https://mercier-wood-flooring.com/us/en/hardwood-flooring/red-oak-balance-source",
        isURLEmpty: false,
        isURLValid: true,
        isURLEncoded: true,
        isURLMatch: true,
        image: {
          scrapped: false,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/1.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: true,
          scrappedValue: {
            amount: 23.45,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 12.34,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: true,
          scrappedValue: true,
          dbValue: true,
        },
        sku: {
          scrapped: true,
          scrappedValue: "TEST_SKU",
          dbValue: "DECMAKUMTHEX10M",
        },
      },
      {
        id: 1,
        key: 1,
        productId: 72,
        productType: "FLOORTILE",
        productName: {
          scrapped: true,
          dbValue: "Makoto Umi Terracotta",
          scrappedValue: "Makoto Umi Terracotta",
        },
        vendor: "Bedrosians",
        startDate: "2024-10-17T12:38:57.566642828Z",
        endDate: "2024-10-17T12:38:57.566651592Z",
        status: "IN_PROGRESS",
        duplicatedByName: false,
        duplicatedByImage: false,
        productURL:
          "https://mercier-wood-flooring.com/us/en/hardwood-flooring/red-oak-balance-source",
        isURLEmpty: true,
        isURLValid: false,
        isURLEncoded: false,
        isURLMatch: false,
        image: {
          scrapped: true,
          scrappedValue: null,
          dbValue: "https://https://public.dospace.com/test_results/1.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: false,
          scrappedValue: {
            amount: 23.45,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 12.34,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: false,
          scrappedValue: false,
          dbValue: false,
        },
        sku: {
          scrapped: false,
          scrappedValue: "TEST_SKU",
          dbValue: "DECMAKUMTHEX10M",
        },
      },
      {
        id: 2,
        key: 2,
        productId: 73,
        productType: "FLOORTILE",
        productName: {
          scrapped: true,
          dbValue: "Makoto Umi Sandstone",
          scrappedValue: "Makoto Umi Sandstone",
        },
        vendor: "Bedrosians",
        startDate: "2024-10-18T09:00:00Z",
        endDate: "2024-10-18T09:00:10Z",
        status: "IN_PROGRESS",
        duplicatedByName: false,
        duplicatedByImage: true,
        productURL: "https://example.com/product1",
        isURLEmpty: false,
        isURLValid: true,
        isURLEncoded: true,
        isURLMatch: true,
        image: {
          scrapped: false,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/2.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: true,
          scrappedValue: {
            amount: 20.0,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 20.0,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: false,
          scrappedValue: true,
          dbValue: false,
        },
        sku: {
          scrapped: true,
          scrappedValue: "SKU_2",
          dbValue: "SKU_73",
        },
      },
      {
        id: 3,
        key: 3,
        productId: 74,
        productType: "FLOORTILE",
        productName: {
          scrapped: true,
          dbValue: "Makoto Umi Slate",
          scrappedValue: "Makoto Umi Slate",
        },
        vendor: "Bedrosians",
        startDate: "2024-10-18T10:00:00Z",
        endDate: "2024-10-18T10:00:10Z",
        status: "IN_PROGRESS",
        duplicatedByName: false,
        duplicatedByImage: false,
        productURL: "https://example.com/product2",
        isURLEmpty: false,
        isURLValid: false,
        isURLEncoded: true,
        isURLMatch: true,
        image: {
          scrapped: false,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/3.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: true,
          scrappedValue: {
            amount: 27.3,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 22.0,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: false,
          scrappedValue: false,
          dbValue: true,
        },
        sku: {
          scrapped: true,
          scrappedValue: "SKU_3",
          dbValue: "SKU_74",
        },
      },
      {
        id: 4,
        key: 4,
        productId: 75,
        productType: "FLOORTILE",
        productName: {
          scrapped: true,
          dbValue: "Makoto Umi Granite",
          scrappedValue: "Makoto Umi Granite",
        },
        vendor: "Bedrosians",
        startDate: "2024-10-18T11:00:00Z",
        endDate: "2024-10-18T11:00:10Z",
        status: "IN_PROGRESS",
        duplicatedByName: false,
        duplicatedByImage: false,
        productURL: "https://example.com/product3",
        isURLEmpty: false,
        isURLValid: true,
        isURLEncoded: true,
        isURLMatch: true,
        image: {
          scrapped: true,
          scrappedValue: "https://public.dospace.com/test_results/1.jpg",
          dbValue: "https://public.dospace.com/test_results/1.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0.7,
        },
        price: {
          scrapped: true,
          scrappedValue: {
            amount: 30.0,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 25.0,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: true,
          scrappedValue: true,
          dbValue: false,
        },
        sku: {
          scrapped: true,
          scrappedValue: "SKU_4",
          dbValue: "SKU_4",
        },
      },
      {
        id: 5,
        key: 5,
        productId: 76,
        productType: "FLOORTILE",
        productName: {
          scrapped: true,
          dbValue: "Makoto Umi Quartzs",
          scrappedValue: "Makoto Umi Quartz",
        },
        vendor: "Bedrosians",
        startDate: "2024-10-18T12:00:00Z",
        endDate: "2024-10-18T12:00:10Z",
        status: "IN_PROGRESS",
        duplicatedByName: false,
        duplicatedByImage: false,
        productURL: "https://example.com/product4",
        isURLEmpty: false,
        isURLValid: false,
        isURLEncoded: false,
        isURLMatch: true,
        image: {
          scrapped: false,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/5.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: true,
          scrappedValue: {
            amount: 32.2,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 28.0,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: true,
          scrappedValue: false,
          dbValue: true,
        },
        sku: {
          scrapped: false,
          scrappedValue: "SKU_5",
          dbValue: "SKU_76",
        },
      },
      {
        id: 6,
        key: 6,
        productId: 77,
        productType: "FLOORTILE",
        productName: {
          scrapped: true,
          dbValue: "Makoto Umi Limestone",
          scrappedValue: "Makoto Umi Limestone",
        },
        vendor: "Bedrosians",
        startDate: "2024-10-18T13:00:00Z",
        endDate: "2024-10-18T13:00:10Z",
        status: "IN_PROGRESS",
        duplicatedByName: false,
        duplicatedByImage: false,
        productURL: "https://example.com/product5",
        isURLEmpty: false,
        isURLValid: false,
        isURLEncoded: true,
        isURLMatch: false,
        image: {
          scrapped: false,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/6.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: true,
          scrappedValue: {
            amount: 35.5,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 30.0,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: false,
          scrappedValue: false,
          dbValue: true,
        },
        sku: {
          scrapped: true,
          scrappedValue: "SKU_6",
          dbValue: "SKU_77",
        },
      },
      {
        id: 7,
        key: 7,
        productId: 736,
        productType: "decors",
        productName: {
          scrapped: true,
          dbValue: "Egg Wood Wall Mirrors",
          scrappedValue: "Egg Wood Wall Mirror",
        },
        vendor: "Hobby Lobby",
        startDate: "2024-10-17T11:07:49.142669740Z",
        endDate: "2024-10-17T11:07:49.142678110Z",
        status: "IN_PROGRESS",
        duplicatedByName: false,
        duplicatedByImage: false,
        productURL:
          "https://redirect.viglink.com?u=https%3A%2F%2Fwww.hobbylobby.com%2FHome-Decor-Frames%2FMirrors-Wall-Decor%2FWall-Mirrors%2FEgg-Wood-Wall-Mirror%2Fp%2FYY4912-81136075&key=5a96913fbd4adb59a834b2bbb5fe0006",
        isURLEmpty: false,
        isURLValid: true,
        isURLEncoded: true,
        isURLMatch: true,
        image: {
          scrapped: false,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/1.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: true,
          scrappedValue: {
            amount: 79.99,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 80,
            currency: "USD",
            unit: "PEACE",
          },
        },
        width: {
          scrapped: true,
          scrappedValue: "22.5",
          dbValue: "22.6",
        },
        height: {
          scrapped: true,
          scrappedValue: "23.69",
          dbValue: "26.70",
        },
        inStock: {
          scrapped: true,
          scrappedValue: true,
          dbValue: true,
        },
        sku: {
          scrapped: true,
          scrappedValue: "TEST_SKU",
          dbValue: "DECMAKUMTHEX10M",
        },
      },
      {
        id: 8,
        key: 8,
        productId: 1811,
        productType: "decors",
        productName: {
          scrapped: false,
          dbValue: "Egg Wood Wall Mirrors",
          scrappedValue: "Egg Wood Wall Mirror",
        },
        vendor: "Hobby Lobby",
        startDate: "2024-10-17T11:07:49.142669740Z",
        endDate: "2024-10-17T11:07:49.142678110Z",
        status: "IN_PROGRESS",
        duplicatedByName: false,
        duplicatedByImage: true,
        productURL:
          "https://redirect.viglink.com?u=https%3A%2F%2Fwww.hobbylobby.com%2FHome-Decor-Frames%2FMirrors-Wall-Decor%2FWall-Mirrors%2FEgg-Wood-Wall-Mirror%2Fp%2FYY4912-81136075&key=5a96913fbd4adb59a834b2bbb5fe0006",
        isURLEmpty: false,
        isURLValid: true,
        isURLEncoded: true,
        isURLMatch: true,
        image: {
          scrapped: false,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/1.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: false,
          scrappedValue: {
            amount: 79.99,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 80,
            currency: "USD",
            unit: "PEACE",
          },
        },
        width: {
          scrapped: false,
          scrappedValue: "22.5",
          dbValue: "22.6",
        },
        height: {
          scrapped: false,
          scrappedValue: "23.69",
          dbValue: "26.70",
        },
        inStock: {
          scrapped: true,
          scrappedValue: true,
          dbValue: true,
        },
        sku: {
          scrapped: true,
          scrappedValue: "TEST_SKU",
          dbValue: "DECMAKUMTHEX10M",
        },
      },
    ],
  };
  const scrapeColumn = [
    {
      title: "ID",
      dataIndex: "",
      render: (record) => {
        // record.isURLEmpty || !record.isURLMatch;
        return (
          <p
            style={{
              background:
                record.isURLEmpty || !record.isURLMatch ? "red" : "none",
            }}
          >
            {record.key}
          </p>
        );
      },
    },
    {
      title: "Product ID",
      dataIndex: "productId",
    },
    {
      title: "Product Name",
      dataIndex: "",
      render: (record) => {
        if (record.isURLEmpty || !record.isURLMatch) {
          return "---";
        }
        const productType = record.productType;
        const productId = record.productId;
        const duplicate = record.duplicatedByName;
        record = record.productName;
        if (duplicate) {
          return <b style={{ color: "orange" }}>DUPLICATE</b>;
        }
        if (!record.scrapped) {
          return <b style={{ color: "red" }}>SCRAPE IS NOT WORKED</b>;
        } else {
          if (record.dbValue === record.scrappedValue) {
            return <b style={{ color: "green" }}>OKAY</b>;
          } else {
            return (
              <Button
                onClick={() =>
                  dispatch(
                    set_is_modal({
                      open: { ...record, productType, productId, type: "name" },
                      type: "scrapeProblem",
                    })
                  )
                }
                style={{ color: "red" }}
              >
                problem
              </Button>
            );
          }
        }
      },
    },
    {
      title: "Product Type",
      dataIndex: "productType",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "In Stock",
      dataIndex: "",
      render: (record) => {
        if (record.isURLEmpty || !record.isURLMatch) {
          return "---";
        }
        const productType = record.productType;
        const productId = record.productId;
        record = record.inStock;
        if (!record.scrapped) {
          return <b style={{ color: "red" }}>SCRAPE IS NOT WORKED</b>;
        } else {
          if (
            record.dbValue === true &&
            record.dbValue === record.scrappedValue
          ) {
            return "In Stock";
          } else {
            return (
              // <span style={{ color: "red" }}>{`Out of stock: ${
              //   !record.dbValue
              //     ? "db value is " + record.dbValue
              //     : !record.scrappedValue
              //     ? "scraped value is " + record.scrappedValue
              //     : ""
              // }`}</span>

              <Button
                onClick={() =>
                  dispatch(
                    set_is_modal({
                      open: {
                        ...record,
                        productType,
                        productId,
                        type: "inStock",
                      },
                      type: "scrapeProblem",
                    })
                  )
                }
                style={{ color: "red" }}
              >
                problem
              </Button>
            );
          }
        }
        // return `${record.scrapped}`;
      },
    },
    {
      title: "Image",
      dataIndex: "",
      render: (record) => {
        if (record.isURLEmpty || !record.isURLMatch) {
          return "---";
        }
        const productType = record.productType;
        const productId = record.productId;
        const duplicate = record.duplicatedByImage;
        record = record.image;
        if (duplicate) {
          return <b style={{ color: "orange" }}>DUPLICATE</b>;
        }
        if (!record.scrapped) {
          return <b style={{ color: "red" }}>SCRAPE IS NOT WORKED</b>;
        } else {
          return (
            <>
              similarity - {record.similarity}
              <br />
              <Button
                onClick={() =>
                  dispatch(
                    set_is_modal({
                      open: {
                        ...record,
                        productType,
                        productId,
                        type: "image",
                      },
                      type: "scrapeProblem",
                    })
                  )
                }
              >
                View
              </Button>
            </>
          );
        }
      },
    },
    {
      title: "Url",
      dataIndex: "",
      render: (record) => {
        if (record.isURLEmpty || !record.isURLMatch) {
          return "---";
        }
        return (
          <>
            {/*TODO encoded show in modal*/}
            {record.isURLEncoded ? (
              <span style={{ color: "green" }}>URL EnCoded</span>
            ) : (
              <span style={{ color: "red" }}>URL not EnCoded</span>
            )}
            <br />
            {record.isURLValid ? (
              <span style={{ color: "green" }}>URL Valid</span>
            ) : (
              <>
                <Button
                  onClick={() =>
                    dispatch(
                      set_is_modal({
                        open: {
                          ...record,
                          productType: record.productType,
                          productId: record.productId,
                          type: "url",
                        },
                        type: "scrapeProblem",
                      })
                    )
                  }
                  style={{ color: "red" }}
                >
                  URL not Valid
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "",
      render: (record) => {
        if (record.isURLEmpty || !record.isURLMatch) {
          return "---";
        }
        const productType = record.productType;
        const productId = record.productId;
        record = record.price;

        if (!record.scrapped) {
          return <b style={{ color: "red" }}>SCRAPE IS NOT WORKED</b>;
        }
        if (!record.scrapped) {
          return <b style={{ color: "red" }}>SCRAPE IS NOT WORKED</b>;
        } else {
          if (deepEqual(record.scrappedValue, record.dbValue)) {
            return <b style={{ color: "green" }}>OKAY</b>;
          } else {
            return (
              <Button
                onClick={() =>
                  dispatch(
                    set_is_modal({
                      open: {
                        ...record,
                        productType,
                        productId,
                        type: "price",
                      },
                      type: "scrapeProblem",
                    })
                  )
                }
                style={{ color: "red" }}
              >
                problem
              </Button>
            );
          }
        }
      },
    },
    {
      title: "SKU",
      dataIndex: "",
      render: (record) => {
        if (record.isURLEmpty || !record.isURLMatch) {
          return "---";
        }
        const productType = record.productType;
        const productId = record.productId;
        record = record.sku;
        if (!record.scrapped) {
          return <b style={{ color: "red" }}>SCRAPE IS NOT WORKED</b>;
        } else {
          if (record.dbValue === record.scrappedValue) {
            return <b style={{ color: "green" }}>OKAY</b>;
          } else {
            return (
              <Button
                onClick={() =>
                  dispatch(
                    set_is_modal({
                      open: { ...record, productType, productId, type: "sku" },
                      type: "scrapeProblem",
                    })
                  )
                }
                style={{ color: "red" }}
              >
                problem
              </Button>
            );
          }
        }
      },
    },
    {
      title: "Width",
      dataIndex: "",
      render: (record) => {
        if (record.isURLEmpty || !record.isURLMatch) {
          return "---";
        }
        const productType = record.productType;
        const productId = record.productId;
        record = record["width"];

        if (!record) {
          return null;
        }
        if (!record.scrapped) {
          return <b style={{ color: "red" }}>SCRAPE IS NOT WORKED</b>;
        } else {
          if (record.dbValue === record.scrappedValue) {
            return <b style={{ color: "green" }}>OKAY</b>;
          } else {
            return (
              <Button
                onClick={() =>
                  dispatch(
                    set_is_modal({
                      open: {
                        ...record,
                        productType,
                        productId,
                        type: "width",
                      },
                      type: "scrapeProblem",
                    })
                  )
                }
                style={{ color: "red" }}
              >
                problem
              </Button>
            );
          }
        }
      },
    },
    {
      title: "Height",
      dataIndex: "",
      render: (record) => {
        if (record.isURLEmpty || !record.isURLMatch) {
          return "---";
        }
        const productType = record.productType;
        const productId = record.productId;
        record = record["height"];
        if (!record) {
          return null;
        }
        if (!record.scrapped) {
          return <b style={{ color: "red" }}>SCRAPE IS NOT WORKED</b>;
        } else {
          if (record.dbValue === record.scrappedValue) {
            return <b style={{ color: "green" }}>OKAY</b>;
          } else {
            return (
              <Button
                onClick={() =>
                  dispatch(
                    set_is_modal({
                      open: {
                        ...record,
                        productType,
                        productId,
                        type: "height",
                      },
                      type: "scrapeProblem",
                    })
                  )
                }
                style={{ color: "red" }}
              >
                problem
              </Button>
            );
          }
        }
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (record) =>
        record.duplicatedByName || record.duplicatedByImage ? (
          <Tooltip title="Duplicate">
            <DeleteOutlined
              onClick={() => {
                dispatch(
                  set_is_modal({
                    open: { ...record, scrapeUpdate: true },
                    type: "delete",
                  })
                );
                // dispatch(set_active_item_for_change(record));
              }}
            />
          </Tooltip>
        ) : null,
    },
  ];

  debuggingInfo.forEach((item) =>
    !item.status || item.status === "In Progress" ? (disable = true) : null
  );

  useEffect(() => {
    dispatch(getScraping());
  }, [dispatch]);
  return (
    <div className="logs">
      {loading.debuggingInfo ? (
        <div className={"chartLoading"}>
          <Spin size={"large"} />
        </div>
      ) : (
        <>
          <Table
            rowClassName={(record, index) => {
              // if (record.isURLEmpty || !record.isURLMatch) {
              //   //todo hetagayum grel emptyia te match chi
              //   return "error-row";
              // }
              return index % 2 === 0 ? "table-row-light" : "table-row-dark";
            }}
            // ref={tableRef}
            size={"small"}
            className={"globalTable"}
            rowKey={"id"}
            // loading={loading}
            dataSource={scrapeData.results}
            columns={scrapeColumn}
            bordered
          />
        </>
      )}
    </div>
  );
};

export default Scraping;
